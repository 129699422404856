import React, { lazy } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

const RootLayout = lazy(() => import('../layout/RootLayout'));
const VerifyCode = lazy(() => import('../pages/Kiosk/VerifyCode'));
const SecureBadgeLogin = lazy(() => import('../pages/Admin/SecureBadgeLogin'));
const AdminLogin = lazy(() => import('../pages/Admin/AdminLogin'));
const AdminForgotPassword = lazy(() => import('../pages/Admin/AdminForgotPassword'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<RootLayout />}>
        <Route path="/" element={<VerifyCode />} />
        <Route path="/securebadge" element={<SecureBadgeLogin />} />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/forgetPassword" element={<AdminForgotPassword />} />
      </Route>
    </Route>,
  ),
);

export default router;

/* eslint-disable no-param-reassign */
import axios from 'axios';

const whitelistedUrls = ['authUser', 'health/healthz'];
console.log(process.env.REACT_APP_BASEURL);
const API = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

API.interceptors.request.use(
  (config) => {
    // Check if URL is whitelisted
    if (whitelistedUrls.some((url) => config.url.startsWith(url))) {
      return config;
    }
    // Handle authentication
    try {
      let token;
      const jwtToken = sessionStorage.getItem('jwtToken');
      if (jwtToken) {
        token = JSON.parse(jwtToken);
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        const authtoken = localStorage.getItem('authTokens');
        if (!authtoken) {
          throw new Error('Missing authentication token');
        }
        try {
          token = atob(authtoken);
          config.headers.Authorization = `Bearer ${token}`;
        } catch (error) {
          console.error('Error decoding auth token:', error);
          throw new Error('Invalid authentication token');
        }
      }
    } catch (error) {
      console.error('Authentication error:', error);
    }

    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  },
);

export default API;
